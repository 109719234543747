import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { throttle } from 'lodash';
// import '../node_modules/react-quill/dist/quill.bubble.css';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// import 'jodit/build/jodit.min.css';
// import 'filepond/dist/filepond.min.css';
// import 'react-circular-progressbar/dist/styles.css';
import rootReducer from './redux/reducers'
import authService from './utils/services/auth';
import { settings } from './config/settings';
// import { configureAmplify } from './helper/auth/AWS/configure' /** Uncomment when aws login required */
import Loading from './helper/component/Loading';
import ErrorBoundary from './helper/component/ErrorBoundary';
// import AzureAuthService from "./helper/auth/Azure/AzureAuthService";

import './theme/bootstrap/fonts/flaticon.css';
import './theme/bootstrap/css/style.css';
// import './theme/fontawesome/css/v4-shims.css';
// import './theme/fontawesome/css/all.css';
// /**  Karing Point Fonts
import './theme/fontawesomepro/css/v4-shims.css';
import './theme/fontawesomepro/css/all.css';
// */
// const App = React.lazy(() => import('./shared/App'));
// const AppAWS = React.lazy(() => import('./helper/auth/AWS')); /** Uncomment when aws login required */
const AppAzure = React.lazy(() => import('./helper/auth/Azure'));
// const AppFirebase = React.lazy(() => import('./helper/auth/Firebase')); /** Uncomment when aws login required */

const middleware = [thunk]
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger())
}
const persistedState = authService().loadState();
const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(...middleware)
)
store.subscribe(throttle(() => {
  authService().saveState(store.getState());
}, 1000));

window.store = store;
debugger;
if (window.location.pathname !== "/login/") {
  let _store = store.getState();
  if ((window.location.pathname === "/accessdenied" || window.location.pathname === "/logout" || window.location.pathname === "/sessionexpired") && _store.formLoginReducer) {
    debugger;
    _store.formLoginReducer.loggedIn = false;
    _store.formLoginReducer.isLoading = false;
    authService().saveState(_store);
  } else if (_store.formLoginReducer && !_store.formLoginReducer.loggedIn) {
    localStorage.setItem(settings.loginRequest, window.location.href);
  }
}
if (settings.LOGIN_SERVICE === settings.LOGINMETHOD.FIREBASE) {
  render((
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <Suspense fallback={<Loading enable={true} />}>
            {/* <AppFirebase /> */}
            {/* Uncomment when firebase login required */}
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  ), document.getElementById('root'));
} else if (settings.LOGIN_SERVICE === settings.LOGINMETHOD.AZUREAD || settings.LOGIN_SERVICE === settings.LOGINMETHOD.AZUREB2C) {
  render((
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <Suspense fallback={<Loading enable={true} />}>
            <AppAzure />
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  ), document.getElementById('root'));
} else if (settings.LOGIN_SERVICE == settings.LOGINMETHOD.AWS) {
  // configureAmplify(); // Uncomment when aws login required
  render((
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <Suspense fallback={<Loading enable={true} />}>
            {/* <AppAWS /> */}
            {/* Uncomment when aws login required */}
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  ), document.getElementById('root'));
} else {
  render((
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <Suspense fallback={<Loading enable={true} />}>
            {/* <App /> */}
            {/* Uncomment when aws login required */}
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  ), document.getElementById('root'));
}

