import { settings } from "../../../config/settings";
import ControlService from "../../../helper/controlservice";
import { menu, lightOrDark, hexToRgb, database, filterMenubyRole, email, isOwner, isAdministrator, AuthorizedGroups, AuthorizedApps, gitHubBranch, getClaimsFromApi } from './private'
import AzureAuthService from "../../../helper/auth/Azure/AzureAuthService";
import jwtDecode from 'jwt-decode';


var MetadataStream = [];
const authService = () => {
  return {
    generateTheme: (font, fontfamily, textColor, primary, secondary, success, info, warning, danger, light, dark, errorMsg, successMsg) => {
      debugger;
      try {
        var rootVarStyle = document.createElement("STYLE");
        rootVarStyle.setAttribute('id', 'theme');
        rootVarStyle.setAttribute('type', 'text/css');
        rootVarStyle.innerHTML = `
      ${font}
      :root
      {
        --font :            ${fontfamily};
        --text-color:       ${textColor};
        --primary :         ${primary};
        --primary-text :    ${lightOrDark(primary)};
        --primary-focus :    rgba(${hexToRgb(primary).r}, ${hexToRgb(primary).g},${hexToRgb(primary).b},0.5);
        --secondary :       ${secondary};
        --secondary-text :  ${lightOrDark(secondary)};
        --secondary-focus :  rgba(${hexToRgb(secondary).r}, ${hexToRgb(secondary).g},${hexToRgb(secondary).b},0.5);
        --success :         ${success};
        --success-text :    ${lightOrDark(success)};
        --success-focus :    rgba(${hexToRgb(success).r}, ${hexToRgb(success).g},${hexToRgb(success).b},0.5);
        --info :            ${info};
        --info-text :       ${lightOrDark(info)};
        --info-focus :      rgba(${hexToRgb(info).r}, ${hexToRgb(info).g},${hexToRgb(info).b},0.5);
        --warning :         ${warning};
        --warning-text :    ${lightOrDark(warning)};
        --warning-focus :   rgba(${hexToRgb(warning).r}, ${hexToRgb(warning).g},${hexToRgb(warning).b},0.5);
        --danger :          ${danger};
        --danger-text :     ${lightOrDark(danger)};
        --danger-focus :    rgba(${hexToRgb(danger).r}, ${hexToRgb(danger).g},${hexToRgb(danger).b},0.5);
        --light :           ${light};
        --light-text :      ${lightOrDark(light)};
        --light-focus :     rgba(${hexToRgb(light).r}, ${hexToRgb(light).g},${hexToRgb(light).b},0.5);
        --dark :            ${dark};
        --dark-text :       ${lightOrDark(dark)};
        --dark-focus :      rgba(${hexToRgb(dark).r}, ${hexToRgb(dark).g},${hexToRgb(dark).b},0.5);
        --error-msg :       ${errorMsg};
        --error-msg-text :  ${lightOrDark(errorMsg)};
        --error-msg-focus : rgba(${hexToRgb(errorMsg).r}, ${hexToRgb(errorMsg).g},${hexToRgb(errorMsg).b},0.5);
        --success-msg :     ${successMsg};
        --success-msg-text  :${lightOrDark(successMsg)};
        --success-msg-focus :rgba(${hexToRgb(successMsg).r}, ${hexToRgb(successMsg).g},${hexToRgb(successMsg).b},0.5);
      }`;
        document.head.appendChild(rootVarStyle);
      } catch (ex) {
        debugger;
      }
    },
    loadState: () => {
      try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
          return undefined;
        }
        return JSON.parse(serializedState);
      } catch (error) {
        return undefined;
      }
    },
    saveState: async (state) => {
      if (!ControlService().isTokenResfreshing()) {
        try {
          let _state = {
            formDataReducer: state.formDataReducer,
            formLoginReducer: state.formLoginReducer
          }
          if ((settings.LOGIN_SERVICE === 'azureb2c' || settings.LOGIN_SERVICE === 'azuread') && state.formLoginReducer.loggedIn) {
            var azureAuthService = new AzureAuthService();
            if (!ControlService().isTokenValid(state.formLoginReducer.token)) {
              ControlService().setTokenResfreshing(true);
              try {
                _state.formLoginReducer.loggedIn = false;
                _state.formLoginReducer.isLoading = true;
                _state.formLoginReducer.token = { accesstoken: 'sessionexpired' };
                let serializedState = JSON.stringify(_state);
                localStorage.setItem('state', serializedState);
                azureAuthService.signOut('/sessionexpired');
              } catch (e) {
                console.log(e);
              }
            } else if (ControlService().isTokenValid5Minutes(state.formLoginReducer.token)) {
              ControlService().setTokenResfreshing(true);
              let accountObj = azureAuthService.app.getAllAccounts()[0];
              if (accountObj) {
                try {
                  const tokenResponse = await azureAuthService.app.acquireTokenSilent({
                    account: accountObj,
                    scopes: [settings.REACT_APP_CLIENT_ID]
                  }).catch(async (error) => {
                    ControlService().setTokenResfreshing(false);
                    console.log("silent token acquisition fails.");
                    if (error instanceof Msal.InteractionRequiredAuthError) {
                      console.log("acquiring token using popup");
                      return azureAuthService.app.acquireTokenPopup(azureProvider.authenticationParameters).catch(error1 => {
                        console.error(error1);
                      });
                    } else {
                      console.error(error);
                    }
                  });
                  if (tokenResponse) {
                    ControlService().setTokenResfreshing(false);
                    _state.formLoginReducer.token.expiresOn = tokenResponse.expiresOn;
                    _state.formLoginReducer.token.accesstoken = tokenResponse.accessToken;
                    const serializedState = JSON.stringify(_state);
                    localStorage.setItem('state', serializedState);
                  }

                } catch (err) {
                  ControlService().setTokenResfreshing(false);
                  console.log(err);
                }
              } else {
                ControlService().setTokenResfreshing(false);
              }
            } else {
              const serializedState = JSON.stringify(_state);
              localStorage.setItem('state', serializedState);
            }
          }
          else {
            const serializedState = JSON.stringify(_state);
            localStorage.setItem('state', serializedState);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    omitHeader: () => {
      return window.location.pathname == '/quickbooks/auth' || window.location.pathname == '/callback';
    },
    isOwner: (user) => {
      return isOwner(user);
    },
    isAdministrator: (user) => {
      return isAdministrator(user);
    },
    menu: (idx, token, application = false) => {
      return menu(idx, token, application);
    },
    database: (idx, token, application) => {
      return database(idx, token, application);
    },
    email: (idx, token) => {
      return email(idx, token);
    },
    filterMenubyRole: (MenuList, user) => {
      return filterMenubyRole(MenuList, user);
    },
    SelectApp: (app, token, props) => {
      debugger;
      token.user.ActiveApp = app;
      return new Promise(function (resolve, reject) {
        menu(app.database, token).then(data => {
          token.menu = filterMenubyRole(data ? data : null, token.user);
          database(app.database, token).then(async _data => {
            token.dataconnection = _data;
            var path = "";
            // Settings for Multiple roles in current app
            if (token.menu.claimbindingfield && token.menu.claimapibindingfield) {
              debugger;
              let decodedIdToken = jwtDecode(token.apikey);
              let applicationsclaim = decodedIdToken[token.menu.claimbindingfield];
              if (applicationsclaim) {
                try {
                  let applicationsclaimRes = await getClaimsFromApi(token, applicationsclaim);
                  if (Array.isArray(applicationsclaimRes)) {
                    applicationsclaim = applicationsclaimRes;
                  } else {
                    applicationsclaim = null;
                  }
                } catch {
                  applicationsclaim = [applicationsclaim];
                }
                if (Array.isArray(applicationsclaim) && applicationsclaim.length > 0) {
                  token.menu.applicationclaims = applicationsclaim;
                  token.menu.applicationclaimSelected = applicationsclaim[0].id ? applicationsclaim[0].id : applicationsclaim[0];
                } else {
                  token.menu.applicationsclaim = null;
                }
              }
            }
            props.authSetToken(token);
            if (token.menu && Array.isArray(token.menu.Nav) && token.menu.Nav.length > 0 && token.menu.ApplicationType != 'mobileonly') {
              var Nav = token.menu.Nav.filter(x => x.show !== 'mobileonly');
              path = Nav.length > 0 ? Nav[0].Url : "";
            }
            if (path == "") {
              if (token.menu && token.menu.ApplicationType == 'mobileonly') {
                path = "mobile";
              } else {
                path = "workbench";
              }
            } else {
              props.resetNavBar(true);
            }
            let loginRequest = localStorage.getItem(settings.loginRequest);
            if (loginRequest) {
              localStorage.setItem(settings.loginRequest, '');
              window.location.href = loginRequest;
              resolve();
            }

            if (window.location.search.indexOf('workbench') > -1) {
              props.history.push("/workbench");
              resolve();
            } else {
              var page = Nav && Nav.length > 0 ? Nav[0] : null;
              var _page = page && Array.isArray(page.Layout.Content) ? page.Layout.Content : [];
              if (_page.length > 0) {
                ControlService().loadMetadata(_page[0].formid, token).then(() => {
                  props.history.push("/" + path);
                  resolve();
                })
              } else {
                if (window.location.pathname.indexOf('workbench') > -1) {
                  window.location.reload();
                } else {
                  props.history.push("/" + path);
                  resolve();
                }
              }
            }
          }).catch(err => {
            token.dataconnection = null;
            props.authSetToken(token);
            var path = "";
            if (token.menu && Array.isArray(token.menu.Nav) && token.menu.Nav.length > 0 && token.menu.ApplicationType != 'mobileonly') {
              var Nav = token.menu.Nav.filter(x => x.show !== 'mobileonly');
              path = Nav.length > 0 ? Nav[0].Url : "";
            }
            if (path == "") {
              if (token.menu && token.menu.ApplicationType == 'mobileonly') {
                path = "mobile";
              } else {
                path = "workbench";
              }
            } else {
              props.resetNavBar(true);
            }
            props.history.push("/" + path);
            reject(err);
          });
        }).catch(err => {
          reject(err);
        })
      })
    },
    reloadApp: async (app, token, props) => {
      const response = await AuthorizedApps(token, false, isOwner(token.user) ? "" : token.user.roles);

      var data = Array.isArray(response) ? response : [];
      token.user.UserApps = data.filter(x => x.IsActive === true);
      return authService().SelectApp(app, token, props);
    },
    cacheFormUI: (token) => {

      // if (token.menu && Array.isArray(token.menu.Nav)) {
      //   token.menu.Nav.forEach(page => {
      //     var _page = Array.isArray(page.Layout.Content) ? page.Layout.Content : [];
      //     _page.forEach(item => {
      //       if (MetadataStream.length > 0 && !MetadataStream[item.formid]) {
      //         // forms.push(item.formid);
      //         ControlService().loadMetadata(item.formid, token)
      //       }
      //     })
      //   })
      // }
    },
    AuthorizedGroups: async (token) => {
      return await AuthorizedGroups(token);
    },
    AuthorizedApps: async (token) => {
      return await AuthorizedApps(token);
    },
    loginSuccess: async (token, selectedBranch = null) => {
      debugger;
      const res = await AuthorizedGroups(token, true);
      // Settings for GIT HUB branches
      let group = Array.isArray(res) && res.length > 0 ? res[0] : {};
      if (group.enablegit && group.defaultbranch) {
        token.enablegit = true;
        if (selectedBranch) {
          token.selectedgitbranch = selectedBranch;
        } else {
          const Branches = await gitHubBranch(token);
          if (Array.isArray(Branches)) {
            token.gitbranches = Branches;
            token.selectedgitbranch = group.defaultbranch;
          }
        }
      }
      var ActiveGroupId = token.user.ActiveGroupStr;
      var groups = Array.isArray(res) ? res : [];
      var ActiveGroup = groups.find(x => x.Name == ActiveGroupId);
      if (groups.length > 0 && !ActiveGroup) {
        ActiveGroup = groups[0];
      }
      token.user.groups = groups;
      if (ActiveGroup) {
        token.user.ActiveGroup = ActiveGroup;
      } else {
        ActiveGroup = {};
        ActiveGroup.Name = ActiveGroupId;
        token.user.ActiveGroup = ActiveGroup;
      }
      const response = await AuthorizedApps(token, false, isOwner(token.user) ? "" : token.user.roles);

      var data = Array.isArray(response) ? response : [];
      token.user.UserApps = data.filter(x => x.IsActive === true);

      return token;
    }
  }
}

export default authService;