require('dotenv').config()

export const settings = {
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL ? process.env.REACT_APP_BASE_API_URL : "https://demo.ncameo.com/",
  TEMPLATE_API_URL: process.env.REACT_APP_TEMPLATE_API_URL ? process.env.REACT_APP_TEMPLATE_API_URL : "https://demo.ncameo.com/",

  REACT_APP_AZURE_FUNCTION_ENDPOINT: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT ? process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT : "",
  LOGIN_SERVICE: process.env.REACT_APP_LOGIN_METHOD ? process.env.REACT_APP_LOGIN_METHOD : 'ncameo', // 'aws' | 'gcloud' | 'azuread' | 'azureb2c' | 'firebase' | 'ncameo'
  STORAGE_SERVICE: 'ncameo', // 'aws' | 'gcloud' | 'azuread' | 'azureb2c' | 'firebase' | 'ncameo'
  EMAIL_SERVICE: process.env.REACT_APP_EMAIL_SERVICE ? process.env.REACT_APP_EMAIL_SERVICE : 'sendgrid',
  PROFILE_ENDPOINT: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT ? process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT : "",
  REACT_APP_ENABLE_PERSONA: process.env.REACT_APP_ENABLE_PERSONA ? process.env.REACT_APP_ENABLE_PERSONA : 'no',
  REACT_AZURE_AUTH_URL: process.env.REACT_AZURE_AUTH_URL ? process.env.REACT_AZURE_AUTH_URL : '',
  REACT_APP_NOTIFICATION_HUB_URL: process.env.REACT_APP_NOTIFICATION_HUB_URL ? process.env.REACT_APP_NOTIFICATION_HUB_URL : '',
  REACT_APP_B2CSIGNUP_URL: process.env.REACT_APP_B2CSIGNUP_URL ? process.env.REACT_APP_B2CSIGNUP_URL : '',
  REACT_APP_B2C_BASE_URL: process.env.REACT_APP_B2C_BASE_URL ? process.env.REACT_APP_B2C_BASE_URL : '',
  REACT_APP_TENANT_URL: process.env.REACT_APP_TENANT_URL ? process.env.REACT_APP_TENANT_URL : '',
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : '',
  REACT_APP_STORAGE_KEY: process.env.REACT_APP_STORAGE_KEY ? process.env.REACT_APP_STORAGE_KEY : '',
  REACT_APP_STORAGE_ACCOUNT: process.env.REACT_APP_STORAGE_ACCOUNT ? process.env.REACT_APP_STORAGE_ACCOUNT : '',
  REACT_APP_UPLOAD_CONTAINER: process.env.REACT_APP_UPLOAD_CONTAINER ? process.env.REACT_APP_UPLOAD_CONTAINER : '',
  REACT_APP_UPD_STORAGE_KEY: process.env.REACT_APP_UPD_STORAGE_KEY ? process.env.REACT_APP_UPD_STORAGE_KEY : '',
  REACT_APP_METADATA_CONTAINER: process.env.REACT_APP_METADATA_CONTAINER ? process.env.REACT_APP_METADATA_CONTAINER : '',
  REACT_APP_B2C_PASSWORD_RESET: process.env.REACT_APP_B2C_PASSWORD_RESET ? process.env.REACT_APP_B2C_PASSWORD_RESET : '',
  REACT_APP_AZURE_FUNCTION_KEY: process.env.REACT_APP_AZURE_FUNCTION_KEY ? process.env.REACT_APP_AZURE_FUNCTION_KEY : '',
  REACT_APP_AWS_REGION: process.env.REACT_APP_AWS_REGION ? process.env.REACT_APP_AWS_REGION : '',
  REACT_APP_AWS_USERPOOLID: process.env.REACT_APP_AWS_USERPOOLID ? process.env.REACT_APP_AWS_USERPOOLID : '',
  REACT_APP_AWS_USERPOOL_WEBCLIENTID: process.env.REACT_APP_AWS_USERPOOL_WEBCLIENTID ? process.env.REACT_APP_AWS_USERPOOL_WEBCLIENTID : '',
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : '',

  loginRequest: 'login_request_d37e2afbc9f34871a92ef08ec80216b5',
  APP_PROPERTY_API: "api/AppData/getproperties",
  APP_FORM_API: "api/AppData/get",
  DATA_API: "api/Application/search",
  MENU_API: "api/application/getmenu",
  SAVE_MENU_API: "api/application/Updatemenu",
  NOTIFICATION_UPDATE_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + "/api/notification/update",
  NOTIFICATION_READ_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + "/api/notification",
  NOTIFICATION_QUE_API: "/api/notificationque",
  RESETPASSWORD_API: "api/account/ForgotPassword",
  LIST_EMAIL_API: 'api/appdata/listemailsource',
  LIST_DATASOURCE_API: 'api/appdata/listdatasource',
  GIT_BRANCH_API: "api/github/branch/list",
  ENVIRONMENT_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/environmentsource',
  GIT_PUBLISH_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/publish',
  GIT_CREDENTIAL_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/github/crendential',

  KEY_VAULT_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/keyvault',

  SEND_SMS_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/SendSMS',
  GET_SMS_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/sms/getbyid',
  QUICKBOOKS_INITIATE_AUTH_API: '/api/quickbook/initiateauth',
  QUICKBOOKS_GENARTE_TOKEN_API: '/api/quickbook/generateaccesstoken',
  QUICKBOOKS_TOKEN_ISVALID_API: '/api/quickbook/status',
  PRINT_PDF_API: '/api/adobe/mergedocumenttopdf',
  OAUTH_INITIATE_AUTH_API: '/api/gcloud/initiateauth',
  OAUTH_ACCESSTOKEN_API: '/api/gcloud/accesstoken',
  OAUTH_GCLOUD_STATUS_API: '/api/gcloud/status',
  GOOGLE_DOC_AI_API: '/api/google/docai',
  PLUGIN_PUBLISH_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/publish/upload',
  TEAM_LIST_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/user/list',
  TEAM_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/user',
  ROLE_LIST_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/role/list',
  ROLE_API: process.env.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/api/role',

  ELAVON_PAY_TOKEN: '/api/convergepay/generateaccesstoken',

  TOKEN: 'token',

  LOADER: {
    radius: 2,
    margin: "2px",
    height: 16,
    width: 5,
    color: '#123abc'
  },

  // Auth Keys
  Google: {
    apikey: process.env.REACT_APP_GOOGLEAPIKEY,
    ClientId: process.env.REACT_APP_GOOGLECLIENTID,
    ClientSecret: process.env.REACT_APP_GOOGLECLIENTSECRET
  },
  // to get countries, state and city list
  universalTutorial: {
    apiToken: process.env.REACT_APP_UNITTUTORIAL_APITOKEN,
    email: process.env.REACT_APP_UNITTUTORIAL_EMAIL
  },
  // payment gateway implements
  PAYMENT: {
    strip: {
      publishableKey: process.env.REACT_APP_PAYMENT_STRIP_PUBLISH_KEY
    },
    paypal: {
      sandbox: process.env.REACT_APP_PAYMENT_STRIP_PUBLISH_KEY
    }
  },
  DATASOURCE: {
    DYNAMODB: 'dynamodb',
    S3: 's3',
    BLOB: 'blob',
    FIREBASE: 'firebase',
    FIREBASESTORE: 'firebasestore',
    HTTP: 'http',
    COSMOSDB: 'cosmosdb',
    AZUREFUNCTION: 'azurefunction',
    SQLDB: 'sql',
    MYSQLDB: 'mysql',
    GITHUB: 'github',
    DEVOPS: 'devops'
  },
  LOGINMETHOD: {
    AWS: 'aws',
    NCAMEO: 'ncameo',
    GCM: 'gcm',
    FIREBASE: 'firebase',
    AZUREAD: 'azuread',
    AZUREB2C: 'azureb2c'
  },
  METHOD: {
    PUT: 'put',
    GET: 'get',
    LIST: 'list',
    POST: 'post',
    DELETE: 'delete'
  },
  WORKBENCH: {
    APPLICATION: 'Application',
    FORMLIST: 'formlist',
    MENU: 'menu',
    DATACONNECTIONS: 'dataconnections',
    EMAILCONNECTIONS: 'emailconnections',
    GROUP: 'group'
  },
  excludedFormId: [
    "824fe445-bf32-424e-921d-4dd5c4383dff",
    "073786c1-2cc4-488d-9a94-de4bf770fc58",
    "86ebbc57-ef7d-482b-ad3f-aee9f1a690b9",
    "e9fb38d7-1db7-46b7-9413-8f1ec058c5b2",
    "06fdcd1e-0c81-4342-af7d-80b52824b834",
    "6ce6e4f0-75e2-44e2-a4c9-454977478fe4",
    "df4e00c1-5f82-4419-affd-5a3e86fed969",
    "182e0c9b-cc4c-422f-8d0b-706f29f6209a",
    "88dd5ace-1c8a-4eff-8049-38ad61913779",
    "01a2e604-f407-4d7d-b7e9-3f259e442361",
    "e74e01ce-1ace-41c3-a47d-526495f2bcf5",
    "b90b92c2-7766-4ebf-bbe9-d245295fd2df",
    "50670547-190a-4ea1-9b08-9cdc5a32e801",
    "bc987a7c-3186-4a61-b61f-2f0c74a074b0",
    "50f50313-9b07-4be7-8ae2-c0d0ffc5e5c1"
  ]
};