import Network from "../http/http/network";
import { settings } from "../../../config/settings";
const uuidv4 = require('uuid/v4');

var stream = [];
const cosmosService = resource => {
  var { datasource, model, modelname, connection, cosmosquery, method, params, sprocname, Token, control } = resource;
  var api = datasource == settings.DATASOURCE.COSMOSDB ? 'entity' : datasource == settings.DATASOURCE.SQLDB ? 'mssql' : datasource == settings.DATASOURCE.MYSQLDB ? 'mysql' : 'dynamodb';
  var ENDPOINT = "";
  if (Token && Token.AzureFunctionEndpoint) {
    ENDPOINT = Token.AzureFunctionEndpoint;
  } else {
    ENDPOINT = settings.REACT_APP_AZURE_FUNCTION_ENDPOINT;
  }
  let sprocs = (partitionKey, headers) => {
    let _model = typeof model == 'string' ? JSON.parse(model) : model;

    var options = {};
    options["cid"] = connection.id;
    options["model"] = partitionKey;

    var payload = {};
    payload = {
      "parameters": params,
      "payload": _model
    };
    var url = ENDPOINT + "/api/" + api + "/storeprocedure" + "/" + sprocname;
    return Network().post(url, payload, Token, options, headers, connection)
      .then(response => {
        return { "status": "success", "statusCode": 200, "data": response.data };
      }).catch(Err => {
        return { "status": "error", "statusCode": 400 };
      });
  }
  let readAF = (partitionKey, query, headers) => {
    var url = ENDPOINT + "/api/" + api + "/SearchAsync";
    var options = {};
    options["cid"] = connection.id;
    options["model"] = partitionKey;
    var payload = {};
    if (params) {
      payload = {
        "query": query,
        "parameters": params
      };
    } else {
      payload = {
        "query": query
      };
    }
    if (control.enablecache) {
      var QueryStr = Object.keys(params ? params : [])
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
      QueryStr = QueryStr + payload.query;
      if (!stream[QueryStr]) {
        stream[QueryStr] = Network().post(url, payload, Token, options, headers, {});
      }

      return stream[QueryStr];
    } else {
      return Network().post(url, payload, Token, options, headers, {});
    }
  }
  let getAF = (partitionKey, headers) => {
    params["cid"] = connection.id;
    params["model"] = partitionKey;

    var url = ENDPOINT + "/api/" + api;

    return Network().get(url, params, Token, headers, connection);
  };
  let createAF = (partitionKey, headers) => {
    model = typeof model == 'string' ? JSON.parse(model) : model;
    if (connection.partitionkey) {
      model[connection.partitionkey] = partitionKey;
      let uuid = uuidv4();
      model["id"] = uuid;
    }
    var options = params;
    options["cid"] = connection.id;
    options["model"] = partitionKey;
    var url = ENDPOINT + "/api/" + api;
    return Network().post(url, model, Token, options, headers, {});
  };
  let updateAF = (partitionKey, headers) => {
    model = typeof model == 'string' ? JSON.parse(model) : model;
    if (connection.partitionkey) {
      model[connection.partitionkey] = partitionKey;
    }
    var options = params;
    options["cid"] = connection.id;
    options["model"] = partitionKey;
    if (Token && Token.user && Token.user.ActiveApp && !options['idx']) {
      options['idx'] = Token.user.ActiveApp.database;
    }
    var url = ENDPOINT + "/api/" + api;
    return Network().put(url, model, options, Token, headers, connection);
  };
  let deleteAF = (partitionKey, headers) => {
    params["cid"] = connection.id;
    params["model"] = partitionKey;
    var url = ENDPOINT + "/api/" + api;
    return Network().delete(url, params, Token, headers);

  }

  return {
    call: () => {
      // connection.enablecors = true;
      var headers = [];
      headers.push({
        key: 'Content-Type', value: 'application/json'
      },
        {
          key: 'Cache-Control', value: 'no-cache, no-store, must-revalidate'
        },
        {
          key: 'Pragma', value: 'no-cache'
        },
        {
          key: 'Expires', value: 0
        },
        {
          key: 'X-XSS-Protection', value: 1
        },
        {
          key: 'Strict-Transport-Security', value: 'max-age=65540 ; includeSubDomains'
        });
      if (settings.REACT_APP_ENABLE_PERSONA == "yes") {
        const accesskey = Token.XZUMOAUTH;
        headers.push({
          key: 'X-ZUMO-AUTH', value: accesskey
        });
        headers.push({
          key: 'org', value: Token.user.companyid
        });
      } else {
        headers.push({
          key: 'x-functions-key', value: settings.REACT_APP_AZURE_FUNCTION_KEY
        });
      }


      const idTokens = Token.apikey;
      if (idTokens) {
        headers.push({
          key: 'api-key', value: idTokens
        });
      }

      debugger;
      headers.push({
        key: 'Authorization', value: 'Bearer ' + Token.accesstoken
      });
      switch (method) {
        case 'create':
          return createAF(modelname, headers);
        case 'post':
          return createAF(modelname, headers);
        case 'update':
          return updateAF(modelname, headers);
        case 'delete':
          return deleteAF(modelname, headers);
        case 'get':
          return getAF(modelname, headers);
        case 'sprocs':
          return sprocs(modelname, headers);
        default:
          return readAF(modelname, cosmosquery, headers);
      }
    }
  }



}

export default cosmosService;